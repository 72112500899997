import { InjectionToken, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routes';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';

import { InMemoryCache } from '@apollo/client/cache';

import { AppSettings } from './app-settings';
import { CommonModule } from '@angular/common';
import { extractFiles } from './extract-files';
import { FeatureFlagModule } from '@codenteam/core/feature-flags/frontend';
import { onError } from '@apollo/client/link/error';
import { ApolloLink } from '@apollo/client/core';
import { AccountExceededServiceAndAccessibility } from './account-exceeded.service';
import { SentryModule } from './core/sentry/sentry.module';
import { TourService } from './tour.service';
import { IconsModule } from '@codenteam/ui/icons/icons.module';
import { RoutesService } from '@codenteam/core/routes';
import { DOCUMENT } from '@angular/common';
import { AnalyticsModule } from './core/analytics/analytics.module';
import { environment } from '../environments/environment';
import { RequestQueueLink } from './request-queue/request-queue.link';
import { RequestQueueStatusComponent } from './request-queue/app-request-queue-status.component';
import { HomeComponent } from './home/home.component';
import { FeedbackModule } from '@codenteam/ui/feedback/feedback.module';

export enum UserAccessibilityStatus {
  EXCEEDED_LIMIT = 'Exceeded account',
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    HomeComponent,
    ApolloModule,
    BrowserModule,
    FormsModule,
    RequestQueueStatusComponent,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FeatureFlagModule,
    SentryModule,
    IconsModule,
    FeedbackModule,
    AnalyticsModule.forRoot(
      environment.mixpanelToken,
      'mixpanel',
      environment.local
    ),
  ],
  providers: [
    {
      provide: 'APP_SETTINGS',
      useValue: new AppSettings(),
    },
    {
      provide: RoutesService,
      useFactory(document: Document) {
        // Handle the origin extraction
        const origin = document.location.origin;
        return new RoutesService('/', origin);
      },
      deps: [DOCUMENT],
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory(
        requestQueueLink: RequestQueueLink,
        httpLink: HttpLink,
        accountExceededService: AccountExceededServiceAndAccessibility
      ) {
        const errorLink = onError(({ graphQLErrors, networkError }) => {
          if (graphQLErrors) {
            graphQLErrors.forEach(({ message, locations, path }) => {
              if (!message) {
                return;
              }
              if (message?.includes(UserAccessibilityStatus.EXCEEDED_LIMIT)) {
                accountExceededService.handelAccountExceededAndAccessibilityError();
              }
              if (message.includes('Forbidden resource')) {
                accountExceededService.handelAccountExceededAndAccessibilityError(
                  true
                );
              }
            });
          }

          if (networkError) {
            if (
              networkError.message.includes(
                UserAccessibilityStatus.EXCEEDED_LIMIT
              )
            ) {
              accountExceededService.handelAccountExceededAndAccessibilityError();
            }
          }
        });

        return {
          defaultOptions: {
            watchQuery: {
              fetchPolicy: 'no-cache',
              errorPolicy: 'ignore',
            },
            query: {
              fetchPolicy: 'no-cache',
              errorPolicy: 'all',
            },
          },
          cache: new InMemoryCache(),
          link: ApolloLink.from([
            requestQueueLink,
            errorLink,
            httpLink.create({
              uri: new AppSettings().graphqlPath,
              withCredentials: true,
              extractFiles: extractFiles,
            }),
          ]),
        };
      },
      deps: [
        RequestQueueLink,
        HttpLink,
        AccountExceededServiceAndAccessibility,
      ],
    },
    TourService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

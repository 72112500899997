import { Component, OnInit } from '@angular/core';
import { RequestQueueLink } from './request-queue.link';
import { interval, map, Observable } from 'rxjs';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { animate, style, trigger, transition } from '@angular/animations';

@Component({
  imports: [CommonModule, MatProgressSpinnerModule],
  standalone: true,
  selector: 'app-request-queue-status',
  template: `
    <div
      class="fixed bottom-10 right-10 z-10 print:!hidden"
      *ngIf="values$ | async as value"
    >
      <div
        class="flex w-auto items-center justify-center rounded bg-slate-950 p-2 text-white"
        *ngIf="value.all - value.done > 2"
        [@fly-left]
      >
        <mat-progress-spinner
          color="primary"
          [diameter]="30"
          mode="determinate"
          [value]="(100.0 * value.done) / value.all"
        ></mat-progress-spinner>
        <div class="hidden flex-1 text-center">
          {{ value.done }} / {{ value.all }}
        </div>
      </div>
    </div>
  `,
  animations: [
    trigger('fly-left', [
      transition(':enter', [
        style({ transform: 'translateX(0%)', opacity: 0 }),
        animate('500ms', style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('500ms', style({ transform: 'translateX(0%)', opacity: 0 })),
      ]),
    ]),
  ],
})
export class RequestQueueStatusComponent implements OnInit {
  activeRequests$: Observable<number>;
  queueLength$: Observable<number>;
  activeArray: any[];
  values$: Observable<{
    done: number;
    all: number;
  }>;
  constructor(private requestQueue: RequestQueueLink) {}

  ngOnInit() {
    // Reload every second
    this.values$ = this.requestQueue.change$;
  }
}

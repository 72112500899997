export enum ScanningCodeVulnTypes {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  INFO = 'INFO',
}

export const scanningCodeSeverityOrder: Record<ScanningCodeVulnTypes, number> =
  {
    [ScanningCodeVulnTypes.ERROR]: 0,
    [ScanningCodeVulnTypes.WARNING]: 1,
    [ScanningCodeVulnTypes.INFO]: 2,
  };

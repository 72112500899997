<form
  [formGroup]="form"
  class="flex flex-1 items-center gap-1 align-middle"
>
  <span>New Scan</span>
  <mat-form-field
    class="flex-1"
    subscriptSizing="dynamic"
  >
    <mat-label>your email</mat-label>
    <input
      matInput
      placeholder="email"
      formControlName="email"
      type="email"
    />
  </mat-form-field>
  <mat-form-field
    class="flex-1"
    subscriptSizing="dynamic"
  >
    <mat-label>Website</mat-label>
    <input
      matInput
      placeholder="Website"
      formControlName="website"
    />
  </mat-form-field>
  <button
    mat-raised-button
    type="submit"
    color="primary"
    [disabled]="!form.valid"
    (click)="createPassiveScan()"
  >
    Scan
  </button>
</form>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedbackService } from './feedback.service';
import { CustomFeedbackPopupComponent } from './custom-feedback-popup/custom-feedback-popup.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [CustomFeedbackPopupComponent],
  imports: [CommonModule, RouterModule],
  providers: [FeedbackService],
})
export class FeedbackModule {}

<ng-container *ngFor="let runUuid of arrayOfRunsUUID">
  <div class="printing-section">
    <codenteam-company-analysis-report
      [runId]="runUuid"
      (completed)="onComplete(runUuid, $event)"
      [logoPreview$]="logoPreview$"
    ></codenteam-company-analysis-report>
  </div>
  <ng-container *ngFor="let team of runTeams$[runUuid] | async as teams">
    <div class="printing-section">
      <codenteam-teams-analysis-report
        [runId]="runUuid"
        [team]="team"
        (completed)="onTeamComplete(team.id, teams, $event)"
        [logoPreview$]="logoPreview$"
      ></codenteam-teams-analysis-report>
    </div>
  </ng-container>

  <div class="printing-section">
    <codenteam-scanning-code-report
      [uuid]="runUuid"
      [logoPreview$]="logoPreview$"
    ></codenteam-scanning-code-report>
  </div>
</ng-container>
<ng-container *ngFor="let uuid of arrayOfScansUUID; let i = index">
  <div class="printing-section">
    <codenteam-penTest-scan-report
      [uuid]="uuid"
      [index]="i"
      (completed)="onCompleteGeneric('pentest', $event, true)"
      [logoPreview$]="logoPreview$"
    ></codenteam-penTest-scan-report>
  </div>
</ng-container>
<ng-container *ngIf="!loadingAllDone && print">
  <div
    class="fixed left-0 top-0 z-50 flex h-screen w-screen flex-col items-center justify-center bg-slate-900 print:hidden"
  >
    <mat-spinner></mat-spinner>
    <br />
    <div
      class="text-white"
      [innerHTML]="loadingText()"
    ></div>
  </div>
</ng-container>

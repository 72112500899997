import { Route } from '@angular/router';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { SettingsComponent } from './settings/settings.component';
import { MainLayoutComponent } from './layouts/main/main.component';
import { CreateReportComponent } from './report/create-report.component';
import { ReportReviewComponent } from './report/report-review/report-review.component';
import { GuestComponent } from './layouts/guest/guest.component';
import { StartupApplyComponent } from './startup-apply/startup-apply.component';
import { TeamsAnalysisReportComponent } from './report/teams-analysis-report/teams-analysis-report.component';
import { HomeComponent } from './home/home.component';
import { PassivePentestScanComponent } from './passive-pentest-scan/passive-pentest-scan.component';

const appRoutes: Route[] = [
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'wizard',
    loadChildren: () =>
      import('./wizard/wizard.module').then((m) => m.WizardModule),
  },
  {
    path: 'hiring',
    loadChildren: () =>
      import('./hiring/hiring.module').then((m) => m.HiringModule),
  },
  {
    path: 'scans',
    loadChildren: () =>
      import('./scans/scans.module').then((m) => m.ScansModule),
  },
  {
    path: 'home',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
      },
    ],
  },
  {
    path: 'settings',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: SettingsComponent,
      },
    ],
  },
  {
    path: 'report',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: CreateReportComponent,
      },
    ],
  },
  {
    path: 'pdf/teams-analysis',
    component: TeamsAnalysisReportComponent,
  },
  // TODO: Move to lazy loaded module
  {
    path: 'report/report-review',
    component: ReportReviewComponent,
  },
  {
    path: 'startup-apply',
    component: GuestComponent,
    children: [
      {
        path: '',
        component: StartupApplyComponent,
      },
    ],
  },
  {
    path: 'passive-pentest-scan',
    component: GuestComponent,
    children: [
      {
        path: '',
        component: PassivePentestScanComponent,
      },
    ],
  },
  {
    path: 'runs/:runId/analysis',
    loadChildren: () =>
      import('./analysis/analysis.module').then((m) => m.AnalysisModule),
  },
  {
    path: 'investor-hub',
    loadChildren: () =>
      import('./investor-hub/investor-hub.module').then(
        (m) => m.InvestorHubModule
      ),
  },
  {
    path: 'projects',
    loadChildren: () =>
      import('./project-hub/project-hub.module').then(
        (m) => m.ProjectHubModule
      ),
  },
  {
    path: 'job-apply',
    loadChildren: () =>
      import('./job-apply/job-apply.module').then((m) => m.JobApplyModule),
  },
  { path: '**', redirectTo: 'auth' },
];
@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled',
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}

import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { map, Observable, switchMap } from 'rxjs';
import { ApiService } from '../../core/api.service';
import {
  LicensesAndVulnerabilitiesAggregation,
  Packages,
  ReportLicensesAndVulnerabilities,
  VulnerabilitiesAggregation,
} from '@codenteam/portal/graphql';
import { LicenseAndVulnerabilityService } from '../../core/license-and-vulnerability.service';
import { LetDirective } from '@ngrx/component';

@Component({
  selector: 'codenteam-licenses-scan-report',
  standalone: true,
  imports: [CommonModule, LetDirective],
  templateUrl: './licenses-scan-report.component.html',
  styleUrl: './licenses-scan-report.component.css',
})
export class LicensesScanReportComponent implements OnInit {
  @Input()
  uuid: string;
  @Input()
  logoPreview$: Observable<string | ArrayBuffer>;
  account$ = this.apiService.getAccountData();
  userInfo$ = this.apiService.getUserInfo();
  licensesAndVulnerabilitiesData$: Observable<ReportLicensesAndVulnerabilities>;
  orderedVuln: {
    table1: Packages[];
    table2: Packages[];
    table3: Packages[];
  };
  run$: Observable<{ uuid: string; name?: string; date?: any; status: string }>;
  constructor(
    private apiService: ApiService,
    private licensesAndVulnerabilitiesService: LicenseAndVulnerabilityService
  ) {}
  ngOnInit(): void {
    this.run$ = this.apiService.getRun(this.uuid);
    this.licensesAndVulnerabilitiesData$ =
      this.licensesAndVulnerabilitiesService
        .getLicensesAndVulnerabilitiesForReport(this.uuid)
        .pipe(
          map((res: ReportLicensesAndVulnerabilities) => {
            this.orderedVuln = this.extractAndSortPackages(res.vulnerabilities);
            return res;
          })
        );
  }
  extractAndSortPackages(vulnerabilities: Packages[]): {
    table1: Packages[];
    table2: Packages[];
    table3: Packages[];
  } {
    const total = vulnerabilities.length;
    const third = Math.ceil(total / 3);

    return {
      table1: vulnerabilities.slice(0, third),
      table2: vulnerabilities.slice(third, 2 * third),
      table3: vulnerabilities.slice(2 * third),
    };
  }

  getScoreColor(score: number): string {
    if (score >= 0 && score <= 4) {
      return 'text-blue-600';
    } else if (score > 4 && score <= 6) {
      return 'text-yellow-600';
    } else if (score > 6 && score <= 7.5) {
      return 'text-orange-600';
    } else if (score > 7.5 && score <= 10) {
      return 'text-red-600';
    } else {
      return '';
    }
  }
}

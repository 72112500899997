import { Injectable, Renderer2 } from '@angular/core';
import { Previewer, Handler, registerHandlers } from 'pagedjs';
class RepeatTableHeadersHandler extends Handler {
  private splitTablesRefs: any[];
  constructor(chunker: any, polisher: any, caller: any) {
    super(chunker, polisher, caller);
    this.splitTablesRefs = [];
  }

  afterPageLayout(
    pageElement: any,
    page: any,
    breakToken: { node: any },
    chunker: { source: any }
  ) {
    this.chunker = chunker;
    this.splitTablesRefs = [];
    if (breakToken) {
      const node = breakToken.node;
      const tables = this.findAllAncestors(node, 'table');
      if (node.tagName === 'TABLE') tables.push(node);

      if (tables.length > 0) {
        this.splitTablesRefs = tables.map((t) => (t as any).dataset.ref);

        let thead =
          node.tagName === 'THEAD'
            ? node
            : this.findFirstAncestor(node, 'thead');
        if (thead) {
          let lastTheadNode = thead.hasChildNodes() ? thead.lastChild : thead;
          breakToken.node = this.nodeAfter(lastTheadNode, chunker.source);
        }

        this.hideEmptyTables(pageElement, node);
      }
    }
  }

  hideEmptyTables(
    pageElement: { querySelector: (arg0: string) => any },
    breakTokenNode: any
  ) {
    this.splitTablesRefs.forEach((ref: string) => {
      let table = pageElement.querySelector("[data-ref='" + ref + "']");
      if (table) {
        let sourceBody = table.querySelector('tbody > tr');
        if (
          !sourceBody ||
          this.refEquals(sourceBody.firstElementChild, breakTokenNode)
        ) {
          table.style.visibility = 'hidden';
          table.style.position = 'absolute';
          let lineSpacer = table.nextSibling;
          if (lineSpacer) {
            lineSpacer.style.visibility = 'hidden';
            lineSpacer.style.position = 'absolute';
          }
        }
      }
    });
  }

  refEquals(a: { dataset: { ref: any } }, b: { dataset: { ref: any } }) {
    return a && a.dataset && b && b.dataset && a.dataset.ref === b.dataset.ref;
  }

  findFirstAncestor(
    element: { parentNode: { nodeType: number; matches: (arg0: any) => any } },
    selector: string
  ) {
    while (element.parentNode && element.parentNode.nodeType === 1) {
      if (element.parentNode.matches(selector)) return element.parentNode;
      element = element.parentNode as any;
    }
    return null;
  }

  matches(node: { matches: (arg0: any) => any }, selectors: string[]) {
    const result = selectors.some((selector) => node.matches(selector));
    return result;
  }
  findAllAncestors(
    element: { parentNode: { nodeType: number; matches: (arg0: any) => any } },
    selector: string
  ) {
    const ancestors = [];
    while (element.parentNode && element.parentNode.nodeType === 1) {
      if (element.parentNode.matches(selector))
        ancestors.unshift(element.parentNode);

      element = element.parentNode as any;
    }
    return ancestors;
  }

  layout(rendered: { querySelector: (arg0: string) => any }, layout: any) {
    this.splitTablesRefs.forEach((ref: string) => {
      const renderedTable = rendered.querySelector("[data-ref='" + ref + "']");
      if (renderedTable) {
        if (!renderedTable.getAttribute('repeated-headers')) {
          const sourceTable = this.chunker.source.querySelector(
            "[data-ref='" + ref + "']"
          );
          this.repeatColgroup(sourceTable, renderedTable);
          this.repeatTHead(sourceTable, renderedTable);
          renderedTable.setAttribute('repeated-headers', true);
        }
      }
    });
  }

  repeatColgroup(
    sourceTable: { querySelectorAll: (arg0: string) => any },
    renderedTable: {
      firstChild: any;
      insertBefore: (arg0: any, arg1: any) => void;
    }
  ) {
    let colgroup = sourceTable.querySelectorAll('colgroup');
    let firstChild = renderedTable.firstChild;
    colgroup.forEach((colgroup: { cloneNode: (arg0: boolean) => any }) => {
      let clonedColgroup = colgroup.cloneNode(true);
      renderedTable.insertBefore(clonedColgroup, firstChild);
    });
  }

  repeatTHead(
    sourceTable: { querySelector: (arg0: string) => any },
    renderedTable: {
      insertBefore: (arg0: any, arg1: any) => void;
      firstChild: any;
    }
  ) {
    let thead = sourceTable.querySelector('thead');
    if (thead) {
      let clonedThead = thead.cloneNode(true);
      renderedTable.insertBefore(clonedThead, renderedTable.firstChild);
    }
  }

  nodeAfter(node: { parentNode: any }, limiter: any) {
    if (limiter && node === limiter) return null;
    let significantNode = this.nextSignificantNode(node as any);
    if (significantNode) return significantNode;
    if (node.parentNode) {
      while ((node = node.parentNode)) {
        if (limiter && node === limiter) return null;
        significantNode = this.nextSignificantNode(node as any);
        if (significantNode) return significantNode;
      }
    }
    return null;
  }

  nextSignificantNode(sib: { nextSibling: any }) {
    while ((sib = sib.nextSibling)) {
      if (!this.isIgnorable(sib as any)) return sib;
    }
    return null;
  }

  isIgnorable(node: { nodeType: number }) {
    return (
      node.nodeType === 8 ||
      (node.nodeType === 3 && this.isAllWhitespace(node as any))
    );
  }

  isAllWhitespace(node: { textContent: string }) {
    return !/[^\t\n\r ]/.test(node.textContent);
  }
}

@Injectable()
export class PagedService {
  constructor(private renderer: Renderer2) {
    // registerHandlers(RepeatTableHeadersHandler);
  }
  addPolyfill() {
    // Add pagedjs to head
    const current = 'https://unpkg.com/pagedjs/dist/paged.polyfill.js';
    const beta =
      'https://unpkg.com/pagedjs@0.5.0-beta.1/dist/paged.polyfill.js';
    const script = document.createElement('script');
    script.src = current;
    document.head.appendChild(script);
  }
  print() {
    let paged = new Previewer({});
    // Create new element in body to contain the result
    let preview = document.createElement('div');
    preview.id = 'preview';
    document.body.appendChild(preview);
    const content = this.renderer.selectRootElement('body', true).innerHTML;
    let flow = paged.preview(content, [], preview).then((flow: any) => {
      // Replace body with preview
      document.body.innerHTML = preview.innerHTML;
    });
  }

  attachRunningSection(positions: string[]) {
    // The assumption is that we need the DOM with the correct class, then we add the description of that class, then add the @page rule to reference that
    for (let position of positions) {
      // Add CSS block to html
      let style = document.createElement('style');
      style.innerHTML = `
        .top-center {
          position: running(topCenter);
        }
        @media print {
            @page {
              @top-center {
                content: element(topCenter)
              }
            }
        }
      `;
      document.body.appendChild(style);
    }
  }
  openPrintInNewWindow(close = false) {
    const preview = document.getElementById('preview');
    var newWindow = window.open('about:blank', '', '');
    var w = newWindow.outerWidth;
    var h = newWindow.outerHeight;
    newWindow.document.body.innerHTML = preview.innerHTML;

    // Copy all styles from the original document
    var styles = document.querySelectorAll('style');

    // Append <link rel="stylesheet"> to the array
    var links = document.querySelectorAll('link[rel="stylesheet"]');
    styles.forEach((style) => {
      // If file, get the content and dump it
      if (style.getAttribute('src')) {
        fetch(style.getAttribute('src'))
          .then((response) => response.text())
          .then((text) => {
            var newStyle = newWindow.document.createElement('style');
            newStyle.innerHTML = text;
            newWindow.document.head.appendChild(newStyle);
          });
      } else {
        newWindow.document.head.appendChild(style.cloneNode(true));
      }
    });

    links.forEach((link) => {
      fetch(link.getAttribute('href'))
        .then((response) => response.text())
        .then((text) => {
          var newStyle = newWindow.document.createElement('style');
          newStyle.innerHTML = text;
          newWindow.document.head.appendChild(newStyle);
        });
    });
  }
}

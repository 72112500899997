<div class="px-12">
  <p class="mb-2 text-center text-[34px] font-normal text-white">Hello,</p>
  <div class="flex flex-wrap justify-center gap-3">
    <div
      class="flex w-52 flex-col gap-2 rounded-[10px] bg-slate-700 px-4 py-[0.875rem] hover:cursor-pointer"
      (click)="goToProjectsHubPage()"
    >
      <mat-icon
        class="!h-9 !w-9 text-white"
        svgIcon="project-hub"
      ></mat-icon>
      <p class="text-xl text-white">Projects</p>
      <p class="text-wrap text-sm text-gray-400">
        Find all your code analysis & pentests in a single place.
      </p>
    </div>

    <div
      class="flex w-52 flex-col gap-2 rounded-[10px] bg-slate-700 px-4 py-[0.875rem] hover:cursor-pointer"
      (click)="goToInvestorHubPage()"
    >
      <mat-icon
        class="!h-9 !w-9 text-green-300"
        svgIcon="startup-rocket-icon"
      ></mat-icon>
      <p class="text-xl text-white">Startups</p>
      <p class="text-wrap text-sm text-gray-400">
        All what you need to know about your next investment.
      </p>
    </div>

    <div
      class="flex w-52 flex-col gap-2 rounded-[10px] bg-slate-700 px-4 py-[0.875rem] hover:cursor-pointer"
      (click)="goToHiringJobListingPage()"
    >
      <mat-icon
        class="!h-9 !w-9 text-amber-200"
        svgIcon="hiring-new-icon"
      ></mat-icon>
      <p class="text-xl text-white">Hiring</p>
      <p class="text-wrap text-sm text-gray-400">
        Create a job & hire based on candidate analysis.
      </p>
    </div>

    <div
      class="flex w-52 flex-col gap-2 rounded-[10px] bg-slate-700 px-4 py-[0.875rem] hover:cursor-pointer"
      (click)="gotoReport()"
    >
      <mat-icon
        class="!h-9 !w-9 text-pink-300"
        svgIcon="report-icon"
      ></mat-icon>
      <p class="text-xl text-white">Reports</p>
      <p class="text-wrap text-sm text-gray-400">
        Create reports based on analysis and pentests
      </p>
    </div>

    <!-- <div
      class="flex w-52 flex-col gap-2 rounded-[10px] bg-slate-700 px-4 py-[0.875rem] shadow-[0px_0px_10px_0px_#6DB4FCCC] hover:cursor-pointer"
      (click)="goToHiringJobListingPage()"
    >
      <div
        class="absolute mt-[-30px] rounded-[5px] bg-blue-400 px-2 shadow-[0px_0px_10px_0px_#00000005]"
      >
        <span class="text-xs font-medium text-white"> new </span>
      </div>
      <mat-icon
        class="!h-9 !w-9 text-blue-400"
        svgIcon="codenteam-icon"
      ></mat-icon>
      <p class="text-xl text-white">AI Chat</p>
      <p class="text-wrap text-sm text-gray-400">
        Have an answer about any of your projects.
      </p>
    </div> -->

    <div
      class="flex w-52 flex-col items-center justify-center gap-2 rounded-[10px] border-2 border-slate-700 px-4 py-[0.875rem] hover:cursor-pointer"
      (click)="gotoAccountUsersPage()"
    >
      <mat-icon class="!h-9 !w-9 !text-4xl text-white">settings</mat-icon>
      <p class="text-xl text-white">Settings</p>
    </div>
  </div>

  <p class="mb-3 mt-6 text-xl text-white">Recent</p>

  <div class="flex flex-col gap-2 rounded-[10px] bg-slate-900 p-2.5">
    <ng-container *ngrxLet="activity$ as activities; suspenseTpl: loading">
      <p
        *ngIf="!activities.notifications.length"
        class="text-center"
      >
        No recent activities, start analysis!
      </p>

      <mat-nav-list *ngIf="activities.notifications">
        <a
          *ngFor="let activity of activities.notifications"
          (click)="notificationNavigate(activity)"
        >
          <mat-list-item class="flex items-center">
            <mat-icon
              matListItemIcon
              [class]="icon(activity.type).color"
              [svgIcon]="icon(activity.type).icon"
              class="!my-auto !self-auto"
            ></mat-icon>
            <div matListItemTitle>{{ activity.data.title }}</div>
            <div
              matListItemLine
              class="!ml-[-4px]"
            >
              {{ activity.data?.message }}
            </div>
          </mat-list-item>
        </a>
      </mat-nav-list>
    </ng-container>
    <ng-template #loading>
      <p class="text-center">Loading...</p>
    </ng-template>
  </div>
</div>

import { Injectable } from '@angular/core';
import { FileProcessService } from '../hiring/file-process/file-process.service';
import { InvestorHubService } from '../core/investor-hub.service';
import { StartupLogoUrlData } from '@codenteam/portal/graphql';
import { mergeMap, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StartupCoreService {
  constructor(
    private fileProcessService: FileProcessService,
    private investorHubService: InvestorHubService
  ) {}

  uploadLogo(
    file: File,
    accountUuid: string,
    startupUuid?: string
  ): Observable<StartupLogoUrlData> {
    return this.investorHubService
      .getStartupDataWithUploadLogoUrl(accountUuid, startupUuid)
      .pipe(
        mergeMap((res) => {
          this.fileProcessService.uploadFile(res.logoUrl, file).subscribe();
          return of(res);
        })
      );
  }

  uploadFiles(files: FileList, uploadUrls: string[]) {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const url = uploadUrls[i];
      this.fileProcessService.uploadFile(url, file).subscribe();
    }
  }
}

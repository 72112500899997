export enum ActivityType {
  RUN_COMPLETED = 'RUN_COMPLETED',
  RUN_CREATED = 'RUN_CREATED',

  // HIRING
  JOB_SUBMISSION = 'JOB_SUBMISSION',
  JOB_CREATED = 'JOB_CREATED',

  // INVESTOR
  STARTUP_SUBMISSION = 'STARTUP_SUBMISSION',
}

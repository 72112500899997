import { Component } from '@angular/core';
import { ChatwootService } from '@codenteam/ui/chatwoot/chatwoot.service';
import { environment } from '../environments/environment';
@Component({
  selector: 'codenteam-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'portal';
  isDarkEnable?: boolean = false;
  toggle = false;
  constructor(private chatwootService: ChatwootService) {
    if (environment.production) this.chatwootService.addWidget();
  }
  toggleSide() {
    this.toggle = !this.toggle;
  }
  changeTheme() {
    this.isDarkEnable = !this.isDarkEnable;
  }
}

import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PromptDialogComponent } from './wizard/prompt-dialog/prompt-dialog.component';
import { RoutesService } from '@codenteam/core/routes';

@Injectable({
  providedIn: 'root',
})
export class AccountExceededServiceAndAccessibility {
  constructor(
    private dialogRef: MatDialog,
    private router: Router,
    @Inject(RoutesService) private routesService: RoutesService
  ) {}
  handelAccountExceededAndAccessibilityError(dontHaveAccess?: boolean) {
    //check for forbidden resources and navigate to home
    if (dontHaveAccess !== undefined && dontHaveAccess === true) {
      this.router.navigate(this.routesService.login());
      return;
    }
    const dialog = this.dialogRef.open(PromptDialogComponent, {
      data: {
        name: `You have exceeded your quota limit! Upgrade quota!`,
      },
    });
    dialog.afterClosed().subscribe((res) => {
      if (res === true) {
        this.router.navigate(this.routesService.settings());
      }
    });
  }
}

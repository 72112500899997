import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountDataDto } from '@codenteam/ui/types/account-data';

@Component({
  selector: 'codenteam-report-header',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './report-header.component.html',
  styleUrl: './report-header.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ReportHeaderComponent {
  @Input()
  user: any;
  @Input()
  account: any;

  @Input()
  logo?: string | ArrayBuffer;

  currentDate = new Date();
}

import * as Sentry from '@sentry/angular-ivy';
import { environment } from '../../../environments/environment';
export function initSentry() {
  // A polyfill to fix the problem between Sentry and angular material drag and drop

  document.addEventListener('mousemove', () => {}, {
    passive: false,
    capture: true,
  });
  Sentry.init({
    dsn: 'https://ba02a25522279714e569aa1ddef8c390@bugs.codenteam.com/2',
    environment: environment.name,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/app.codenteam\.com\/api/,
        ],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
        mask: ['type="password"'],
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
